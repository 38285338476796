:root{
    background-color: rgb(240, 240, 240);
}
.BackArrow {
    display: flex;
    align-items: center;
    font-size: 24px;
  }
  
  .BackArrow a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
  
  .BackArrow p {
    margin-left: 0.5rem; /* Adjust the margin as needed */
    font-size: 18px !important;
    font-style: bold !important;
  }
