// About.scss

.vertical-timeline::before {
  background: #adb5bd;
}

.vertical-timeline-element--work {
  .vertical-timeline-element-content {
      padding: 0 !important;
      background: transparent !important;
      box-shadow: none !important;
      width: 400px !important; // Adjusted width
      float: left; // Force align to left

      &:hover {
          transform: none !important;
          box-shadow: none !important;
      }
  }

  &:nth-child(even) .vertical-timeline-element-content {
      float: right; // Force align to right for even elements
  }

  .vertical-timeline-element-content-arrow {
      display: none;
  }

  .date-beside-dot {
      color: black;
      font-size: 2.0rem; // Larger font size
      font-weight: bold;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 10px); // Adjusted distance from the dot
      white-space: nowrap; // Prevents the date from breaking into multiple lines

      // For even elements, position the date on the right side of the dot
      .vertical-timeline-element:nth-child(even) & {
          left: auto;
          right: calc(100% + 10px);
      }

      @media (max-width: 768px) {
          position: relative; // Reset position for mobile
          left: auto; // Reset left for mobile
          right: auto; // Reset right for mobile
          top: auto; // Reset top for mobile
          transform: none; // Reset transform for mobile
          text-align: center; // Center the year text
          margin-bottom: 1rem; // Add margin between year and card on mobile
      }
  }
}

.card {
  padding: 2.5rem 1.75rem 2.5rem 2.5rem;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-bottom: 2rem;

  &:hover {
      box-shadow: 0px 7px 14px -3px rgba(0, 0, 0, 0.1), 0px 3px 6px -2px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 768px) {
      width: calc(100% - 20px); // Adjusted width for mobile
      margin: 0 auto; // Center the card horizontally on mobile
      position: relative; // Reset position for mobile
      top: auto; // Reset top for mobile
      left: auto; // Reset left for mobile
      right: auto; // Reset right for mobile
      text-align: center; // Center card content on mobile
  }
}
