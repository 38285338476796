.custom-hotspot .tooltip {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .custom-hotspot:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }