.collapsible .label {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

.collapsible .content {
  height: 0px;
  transition: height ease-in-out 0.3s;
  overflow: hidden;
}

.collapsible .content.show {
  height: 100px;
}
