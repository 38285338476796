@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Lucida Sans";
  src: url("./fonts/LSANSD.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lucida Sans";
  src: url("./fonts/LSANS.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --primary-color: #edf2f8;
  --secondary-color: #e30423;

  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --transparency: 01;
  /*--navbar-button-background: rgba(220,38,38,var(--transparency)); */
  --navbar-button-background: #0000007c;
  --navbar-button-background-red: #e30423;
  /* --navbar-button-background: rgba(239,68,68,var(--transparency)); */
  --switch-size: 1070px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;

}

.dropdown {
  display: none;
}
