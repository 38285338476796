.slider-img {
    width: 100% !important;
    height: 50vh;
}

.slider-container {
    position: relative;
    width: 100%;
    height: 50%;
}

.slider-item {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.slider-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
            0,
            0,
            0,
            0.4
    ); /* Adjust the alpha value (0.2) to your preference */
}

.slider-item p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 90px 0; /* Add margin to the top and bottom */
    padding: 10px;
    color: #fff;
    font-size: 16px;
    z-index: 1;
}

.slider-item .button-overlay {
    position: absolute;
    bottom: 70px; /* Adjust the position as needed */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    transition: background-color 0.3s;
}

.slider-item .button-overlay:hover {
    background-color: darkred;
}

.slick-dots {
    top: 90%;
    color: rgba(
            255,
            255,
            255,
            0.8
    ) !important; /* Adjust the alpha value (0.8) to your preference */
}

.slick-dots li {
    color: rgba(255, 255, 255, 0.4) !important;
}

.slick-dots li.slick-active {
    color: #fff !important; /* Change the text color of the active dot */
}

.slick-slide {
    height: auto !important;
}
