.imgCards {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    width: 300px;
    height: 300px;
  }
}
