.sub__title {
  margin-bottom: -70px;
}
.custom-hotspot {
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.custom-hotspot:hover {
  background-color: blue;
}

.tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-hotspot:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
