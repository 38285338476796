.Cards {
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

.title {
    font-size: 36px;
    margin-top: -5rem;
}